import Tab from './Tab.js';
import SignUpForm from './SignUpForm.js';
import AboutUvuli from './AboutUvuli.js';
import AboutTheFounders from './AboutTheFounders.js';
import { useState } from 'react';

function TabContainer({children}) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-t border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px justify-center">
          <Tab tabName="Sign Up"
            isActive={activeTab === 0}
            tabSelected={() => setActiveTab(0)} />
          <Tab tabName="About Uvuli"
            isActive={activeTab === 1}
            tabSelected={() => setActiveTab(1)} />
          <Tab tabName="About The Founder"
            isActive={activeTab === 2}
            tabSelected={() => setActiveTab(2)} />
        </ul>
      </div>
      <div class="min-h-dvh overflow-visible border-t border-l border-r border-gray-400 px-3 py-10 bg-gradient-to-br from-primary to-secondary flex justify-center">
        {activeTab === 0 && <SignUpForm />}
        {activeTab === 1 && <AboutUvuli />}
        {activeTab === 2 && <AboutTheFounders />}
      </div>
    </div>

  );
}

export default TabContainer;