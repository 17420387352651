function AboutUvuli() {

  return (
    <div class="w-full max-w-md">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <p>
          Tired of modern dating apps? So are we.
          <br/>
          <br/>
          At Uvuli we are dedicated to not only helping you find love, but to keep it.
          <br/>
          <br/>
          Other modern dating apps help you find a match, but when the going gets tough they're only too happy to provide a space for you to retreat to find someone new. And someone new again. And again, all without learning anything new, while you pay for premium features.
          <br/>
          <br/>
          Uvuli is different. We will help you find and maintain love with potential features like:
          <br/>
          <br/>
          - Detailed matchmaking
          <br/>
          - Date ideas
          <br/>
          - Dating coaching
          <br/>
          - Relationship coaches
          <br/>
          - Community questions and discussions
          <br/>
          - Photo validation to avoid catfishing
          <br/>
          - Feedback options if you feel misled by a match
          <br/>
          <br/>
          And a lot more!
          <br/>
          <br/>
          Uvuli will provide all this at a low, monthly subscription fee to ensure high-quality profiles and matches for long-term love.
          <br/>
          <br/>
          Interested in helping build our Emotionally-Intelligent Dating community? Sign up with your email today.
        </p>
      </div>
    </div>
  );
}

export default AboutUvuli;