import photoOfRichy from './sexyrexy.jpeg';

function AboutTheFounders() {

  return (
    <div class="w-full max-w-md">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <p>
          My name is Richy Kandora and I am a professional software engineer who, unfortunately, also has years of first hand experience with the modern dating app market. As someone who had been looking for a long-term relationship, it was eye-opening to me to see how many "bad actor" businesses there are in the dating space. Thus, over the years through my own personal experience, discussing with friends and family and building a great rapport with my therapist, I had been able to put in a bit of thought as to what I believed to be wrong with dating apps and how they can be improved. Uvuli is not only a business idea of mine but a geniune passion; dating can be emotionally trying and having gone through the rigors of it myself, I feel like I'm in a position to introduce an app that people trust and actually enjoy using. 
          <br/>
          <br/>
          Outside of Uuvli, I am a big sports fan (both playing and watching them) and trivia geek. The idea of continuous improvement has always appealed to me which lends itself to both of those areas: there is always something to get better at or learn (especially in golf!) and I'm always looking for opportunities to develop my skills or knowlege. 
        </p>
        <div class="pt-8">
          <img class="object-center" src={photoOfRichy}  alt="photo" />
        </div>
      </div>
    </div>
  );
}

export default AboutTheFounders;