function Tab({tabName, isActive, tabSelected}) {

  const activeClass = "inline-block p-4 border-b-2 rounded-t-lg text-primary border-primary",
    inactiveClass = "inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-secondary dark:hover:text-gray-300";

  return (
    <li className="me-2 cursor-pointer">
        <p
          className={isActive ? activeClass : inactiveClass}
          onClick={tabSelected}
          >
          
          {tabName} 
        </p>
    </li>
  );
}

export default Tab;